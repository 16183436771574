<template>
<Box>
  
  <ErrorRow 
    :error="error" />
  <SuccessRow 
    :message="message" />

  <SavingDialog 
    v-show="showSavingDialog" 
    @close="closeSavingDialog" />
    
  <TitleRow> 
    {{ C.get(CK.BILLING.DETAILS_TITLE) }}
  </TitleRow>

  <Row :showBorder="true" v-if="!isLoading">
    <Column>
      <Box>

        <Row>
          <Column :width="7">
            <Box>
              <TitleRow :subtitle="true"> Billing Company </TitleRow>
              <KeyValue 
                :title="FIELDS.COMPANY_NAME" 
                :disabled="isSaving" 
                :defaultValue="settings.companyName()" 
                :placeholder="C.get(CK.BILLING.COMPANY_NAME)" 
                v-on:value="setValue" />
            </Box>
          </Column>
            
          <Column :width="8">
            <Box>
              <TitleRow :subtitle="true"> Plan Information </TitleRow>
              <LabelValueRow v-if="hasFeatureVehicles">
                <template #label> Vehicle Storage </template>
                <template #value> ${{ priceVehicles}} per truck or trailer per month </template>
              </LabelValueRow>
              <LabelValueRow v-if="hasFeatureInspections">
                <template #label> Inspections </template>
                <template #value> ${{ priceInspections }} per inspection </template>
              </LabelValueRow>
              <LabelValueRow v-if="hasFeatureTimesheets">
                <template #label> Hours of Service </template>
                <template #value> ${{ priceTimesheets }} per day</template>
              </LabelValueRow>
            </Box>
          </Column>
        
        </Row>
        
        <SpacerRow />
        
        <Row>
          <Column :width="7">
            <Box>
        
              <TitleRow :subtitle="true"> Billing Contact </TitleRow>
              <ValueRow>This will appear on your Invoice</ValueRow>
              
              <KeyValue :title="FIELDS.CONTACT_NAME" 
                :disabled="isSaving" 
                :defaultValue="settings.contactName()" 
                :placeholder="C.get(CK.BILLING.CONTACT_NAME)" 
                v-on:value="setValue" />
            
              <KeyPhone :title="FIELDS.CONTACT_PHONE" 
                :disabled="isSaving" 
                :defaultValue="settings.contactPhone()" 
                :placeholder="C.get(CK.BILLING.CONTACT_PHONE)" 
                v-on:value="setValue" />
              
              <KeyEmail :title="FIELDS.CONTACT_EMAIL" 
                :disabled="isSaving" 
                :defaultValue="settings.contactEmail()" 
                :placeholder="C.get(CK.BILLING.CONTACT_EMAIL)" 
                v-on:value="setValue" />
        
            </Box>
          </Column>
          <Column :width="8">
            <Box>
              <TitleRow :subtitle="true">Payment Information</TitleRow>
              
              <ValueRow>Credit Cards will be charged between the 15th and 30th of the month</ValueRow>
              <ValueRow>We do not accept American Express Cards</ValueRow>
              <SpacerRow />
              <ValueRow v-if="ccCard.isNew()">Credit Card required after free trial ends</ValueRow>
              <SpacerRow v-if="ccCard.isNew()"/>
              <SpacerRow v-if="ccCard.isNew()"/>
              
              <LabelValueRow v-if="!ccCard.isNew()">
                <template #label> Name </template>
                <template #value> {{ ccCard.name() }} </template>
              </LabelValueRow>
              
              <LabelValueRow v-if="!ccCard.isNew()">
                <template #label> Number </template>
                <template #value> {{ ccCard.number() }} </template>
              </LabelValueRow>

              <LabelValueRow v-if="!ccCard.isNew()">
                <template #label> Expires </template>
                <template #value> {{ ccCard.month() }}/{{ ccCard.year() }} </template>
              </LabelValueRow>

              <Row>
                <Column v-if="isAdmin && !ccCard.isNew()" :width="5">
                  <Button v-on:click="removeCard()" :disabled="isRemoving">Remove</Button>
                </Column>
                <Column v-else :width="10"/>
                <Column v-if="isAdmin && !ccCard.isNew()" :width="5" />
                
                <Column :width="5">
                  <Button v-on:click="updateCard()">Update</Button>
                </Column>
              </Row>
            </Box>
          </Column>
        </Row>
        
        <SpacerRow />
                  
        <Row>
          <Column :width="7">
            <Box>
              <TitleRow :subtitle="true"> Billing Address </TitleRow>
              <ValueRow>This will appear on your Invoice</ValueRow>
              <AddressRow 
                :showTitle="false"
                :addressData="address.data" 
                v-on:value="setAddressValue" />
            </Box>
          </Column>
          <Column :width="8">
            <Box>
              <TitleRow :subtitle="true">
                Invoice Email and PO Number
              </TitleRow>
                
              <ValueRow>Invoices will be emailed the first week of month</ValueRow>
              <KeyValue 
                :title="FIELDS.EMAIL_INVOICE_TO" 
                :placeholder="C.get(CK.BILLING.EMAIL_TO)" 
                :disabled="isSaving" 
                :defaultValue="settings.emailTo()" 
                hint="A copy the invoice will be sent to these addresses (separate each email address with a comma)."
                v-on:value="setValue" />
                
              <KeyValue 
                :title="FIELDS.PURCHASE_ORDER" 
                :placeholder="C.get(K.Invoice.PURCHASE_ORDER)" 
                :disabled="isSaving" 
                hint="Purchase order number will appear on the invoice"
                :defaultValue="settings.purchaseOrder()" 
                v-on:value="setValue" />
                
            </Box>
          </Column>
        </Row>
        <SaveRow :left="4" :right="4" 
            :disabled="false" v-on:value="save" />
            
      </Box>
    </Column>
  </Row>
      
  <Row :showBorder="true">
    <Column :width="15">
      <Box>
        <TitleRow :subtitle="true" v-if="isAdmin">
          Admin Section
        </TitleRow>
        
        <LabelValueRow v-if="isAdmin">
          <template #label> Invoice Settings Id </template>
          <template #value> {{ settings.id() }} </template>
        </LabelValueRow>
        
        <KeyList v-if="isAdmin"
          title="paymentMethod" 
          :options="paymentMethods"
          :defaultValue="settings.paymentMethod()" 
          placeholder="Payment Method" 
          v-on:value="setValue"/>
          
        <KeyValue v-if="isAdmin"
          :title="FIELDS.CARD_ID" 
          placeholder="Card ID" 
          :disabled="isSaving" 
          :defaultValue="settings.cardId()" 
          hint="Credit Card ID reference"
          v-on:value="setValue" />

        <KeyValue v-if="isAdmin"
          :title="FIELDS.OVERPAYMENT_BALANCE" 
          placeholder="Over Payment" 
          :disabled="isSaving" 
          :defaultValue="settings.overPaymentBalance()" 
          hint="Total over payments from invoices"
          v-on:value="setValue" />
      </Box>
    </Column>
  </Row>
    
  <LoadingRow v-if="isLoading">{{ loadingMessage }} </LoadingRow>
  
</Box>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import CE from  "@/domain/session/CanadianEnglish.js";
import Catalog from  "@/domain/session/Catalog.js";
import InvoiceSettings from "@/domain/model/invoice/InvoiceSettings.js";
import Address from "@/domain/model/address/Address.js";
import Card from "@/domain/model/invoice/Card.js";

import ConstUtils   from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import StringUtils  from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import AddressRow from "@/components/input/address/AddressRow.vue";
import ErrorRow   from '@/components/row/ErrorRow.vue'
import SuccessRow from '@/components/row/SuccessRow.vue'
import SaveRow    from '@/components/row/SaveRow.vue'

import KeyValue from '@/components/input/KeyValue.vue';
import KeyList from '@/components/input/KeyList.vue';
//import KeyCheck from '@/components/input/KeyCheck.vue';
import KeyPhone from '@/components/input/KeyPhone.vue';
import KeyEmail from '@/components/input/KeyEmail.vue';

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

//import Title from "@/portals/shared/library/title/Title.vue";
import TitleRow from "@/portals/shared/library/title/TitleRow.vue";

import LoadingRow from '@/portals/shared/library/loading/LoadingRow.vue';

//import Spacer from "@/portals/shared/library/spacer/Spacer.vue";
import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";

import Button from "@/portals/shared/library/button/Button.vue";
//import Value from "@/portals/shared/library/value/Value.vue";
import ValueRow from "@/portals/shared/library/value/ValueRow.vue";
import SavingDialog from "@/portals/shared/views/saving/SavingDialog.vue";

//import LabelValue from "@/portals/shared/library/labelvalue/LabelValue.vue";
import LabelValueRow from "@/portals/shared/library/labelvalue/LabelValueRow.vue";

export default {
  name: "operator-billing-edit",
  components: {
    TitleRow, LoadingRow, SavingDialog,
    ErrorRow,
    SuccessRow,
    SaveRow,
    SpacerRow,
    AddressRow,
    ValueRow,
    KeyValue, KeyPhone, KeyEmail, KeyList, //KeyCheck
    Box,Row, Column,
    Button, LabelValueRow,
  },
  props: {
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      error: null,
      message: null,
      addressData: {},
      isSaving: false,
      FIELDS: InvoiceSettings.FIELDS,
      ADDRESS_FIELDS: Address.FIELDS,
      settingsKvps: {},
      ccSettingsKvps: {},
      addressKvps: {},
      ccAddressKvps: {},
      C: new Catalog(),
      K: Catalog,
      CK: CE.KEYS,
      paymentMethods: ContentUtils.PAYMENT_METHODS(),
      settingsData: null,
      Card: Card,
      
      isLoading: true,
      loadingMessage: null,
      goToUpdateCCAfterSave: false,
      showSavingDialog: false,
      isRemoving: false,

      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                  ]),
    settings: function() {
      if (this.settingsData) {
        this.domain.logger().info("settingsData: {0}", [this.settingsData]);
        return new InvoiceSettings(this.domain, this.settingsData);
      }
      if (this.domain && this.domain.session()) {
        var company = this.domain.session().company();
        this.domain.logger().info("company: {0}", [company.id()]);
        var settings = company.invoiceSettings();
        this.domain.logger().info("settings: {0}", [settings.id()]);
        return settings;
      }
      return new InvoiceSettings(this.domain, {});
    },
    
    address: function() {
      if (this.domain) {
        return this.settings.address().find();
      }
      return new Address(this.domain);
    },
    ccAddress: function() {
      return new Address(this.domain, this.ccAddressKvps);
    },
    ccAddressData: function() {
      if (this.domain) {
        return this.ccCard.address().find().data;
      }
      return {};
    },
    ccCard: function() {
      if (this.domain) {
        var card = this.settings.find().card().find();
        this.domain.logger().info("card: {0}", [card.id()]);
        return card;
      }
      return new Card(this.domain);
    },
    hasFeatureVehicles() {
      if (this.domain) {
        return this.domain.session().company().features().find().allowsVehicles();
      }
      return false;
    },
    hasFeatureInspections() {
      if (this.domain) {
        return this.domain.session().company().features().find().allowsInspections();
      }
      return false;
    },
    hasFeatureTimesheets() {
      if (this.domain) {
        return this.domain.session().company().features().find().allowsTimesheets();
      }
      return false;
    },
    priceTimesheets() {
      if (this.hasFeatureTimesheets) {
        var company = this.domain.session().company().find();
        var assets = company.assets().find()
        return assets.priceForTimesheets().toFixed(2);
      }
      return "$0.35";
    },
    priceVehicles() {
      if (this.hasFeatureVehicles) {
        var company = this.domain.session().company().find();
        var assets = company.assets().find()
        return assets.priceForVehicles().toFixed(2);
      }
      return "$0.50";
    },
    priceInspections() {
      if (this.hasFeatureInspections) {
        var company = this.domain.session().company().find();
        var assets = company.assets().find()
        return assets.priceForInspections().toFixed(2);
      }
      return "$0.35";
    }
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.isLoading = false;
      this.loadingMessage = "Done";
    },
    
    callback: function(msg) {
      this.loadingMessage = msg;
    },
    
    setValue: function(kvp) {
      this.error = null;
      this.message = null;
      ContentUtils.set(this.settingsKvps, kvp, this.domain.logger());
    },
    
    setCcValue: function(kvp) {
      this.error = null;
      this.message = null;
      ContentUtils.set(this.ccSettingsKvps, kvp, this.domain.logger());
    },
    
    setCcAddressSameAsBilling: function() {
      this.ccAddress
        .withCountry(this.address.country())
        .withCity(this.address.city())
      ContentUtils.set(this.ccAddressKvps,  { key: Address.FIELDS.STREET, value: this.address.street() }, this.domain.logger());
      ContentUtils.set(this.ccAddressKvps,  { key: Address.FIELDS.CITY, value: this.address.city() }, this.domain.logger());
      ContentUtils.set(this.ccAddressKvps,  { key: Address.FIELDS.JURISDICTION, value: this.address.province() }, this.domain.logger());
      ContentUtils.set(this.ccAddressKvps,  { key: Address.FIELDS.COUNTRY, value: this.address.country() }, this.domain.logger());
      ContentUtils.set(this.ccAddressKvps,  { key: Address.FIELDS.POSTAL, value: this.address.postal() }, this.domain.logger());
    },
    
    setCcAddressValue: function(kvp) {
      this.error = null;
      this.message = null;
      ContentUtils.set(this.ccAddressKvps, kvp, this.domain.logger());
    },
    
    setAddressValue: function(kvp) {
      this.error = null;
      this.message = null;
      ContentUtils.set(this.addressKvps, kvp, this.domain.logger());
    },
    
    save: function() {
      this.error = null;
      if (!this.areRequirementsMet()) {
        return 
      }
      this.isSaving = true;
      this.showSavingDialog = true;
      ContentUtils.set(this.addressKvps,  { key: ConstUtils.FIELDS.ID, value: this.settings.address().id() }, this.domain.logger());
      ContentUtils.set(this.settingsKvps, { key: ConstUtils.FIELDS.ID, value: this.settings.id() }, this.domain.logger());
      ContentUtils.set(this.settingsKvps, { key: ConstUtils.FIELDS.COMPANY, value: this.domain.session().company().id() }, this.domain.logger());
      var event = this.domain.events().invoice().saveSettings(this.settingsKvps, this.addressKvps);
      event.send(this.listener);
    },
    
    listener: function(event) {
      this.isSaving = false;
      this.showSavingDialog = false;
      if (event.error()) {
        this.error = event.error();
      } else {
        this.message = "Billing changes saved successfully";
        var payload = event.payload();
        this.settingsData = payload.valuesFor(InvoiceSettings.MODEL_NAME);
        this.settingsKvps = {};
        if (this.goToUpdateCCAfterSave) {
          this.goToUpdateCCAfterSave = false;
          this.showUpdateCard();
        }
      }
    },
    
    areRequirementsMet: function() {
      var met = true;
      return met;
    },
    
    updateCard: function() {
      if (this.settings.isNew()) {
        this.goToUpdateCCAfterSave = true;
        this.save();
      } else {
        this.showUpdateCard();
      }
    },
    
    removeCard() {

      var company = this.domain.session().company();
      var event = this.domain
                    .events()
                    .invoice()
                    .removeCard(company);

      var context = {};
      context["model"] = this;
      this.isRemoving = true;
      event.send(this.removeCardListener, context);
    },

    removeCardListener(response, context) {
      if (response, context) {
        //
      }
      this.isRemoving = false;
    },

    showUpdateCard: function() {
      this.$router.push({
        name: ConstUtils.ROUTES.PAYMENT.CARD_EDIT,
      })
    },
    
    saving: function() {
      this.showSavingDialog = true;
    },
    
    closeSavingDialog: function() {
     this.showSavingDialog = false;  
    },
  }
}

</script>